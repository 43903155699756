.three-up .col-top {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40px; }
  @media (min-width: 768px) {
    .three-up .col-top {
      flex-flow: row nowrap; } }
  .three-up .col-top .col {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #cacaca; }
    .three-up .col-top .col:last-of-type {
      margin-bottom: 0;
      border-bottom: none; }
    @media (min-width: 500px) {
      .three-up .col-top .col {
        flex-flow: row nowrap; } }
    @media (min-width: 768px) {
      .three-up .col-top .col {
        width: calc(33.333% - 45px);
        flex-flow: column nowrap;
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none; } }
    .three-up .col-top .col .mobile-bottom {
      width: 100%;
      flex: 1 0 auto; }
      @media (min-width: 500px) {
        .three-up .col-top .col .mobile-bottom {
          width: 60%;
          margin-left: 20px; } }
      @media (min-width: 768px) {
        .three-up .col-top .col .mobile-bottom {
          display: none; } }
      .three-up .col-top .col .mobile-bottom p:last-of-type {
        margin-bottom: 0; }

.three-up .col-bottom {
  width: 100%;
  display: none;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px; }
  @media (min-width: 768px) {
    .three-up .col-bottom {
      display: flex; } }
  .three-up .col-bottom .col {
    width: calc(33.333% - 45px);
    display: flex;
    flex-flow: column nowrap; }
    .three-up .col-bottom .col .col-header {
      margin-bottom: 0;
      text-align: center; }

.image-w-text .container {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center; }
  @media (min-width: 650px) {
    .image-w-text .container {
      flex-flow: row wrap;
      justify-content: space-between; } }
  .image-w-text .container .text-col {
    flex: 0 1 100%;
    order: 2; }
    @media (min-width: 650px) {
      .image-w-text .container .text-col {
        flex: 0 1 48%;
        padding-right: 0px;
        order: 1;
        padding-right: 10%; } }
  .image-w-text .container .media-col {
    width: 100%;
    flex: 0 1 100%;
    order: 1;
    margin-bottom: 20px; }
    @media (min-width: 650px) {
      .image-w-text .container .media-col {
        width: 45%;
        flex: 0 1 45%;
        margin-bottom: 0;
        order: 2; } }
    .image-w-text .container .media-col .inline-pic, .image-w-text .container .media-col .inline-pic img {
      width: 100%; }
      .image-w-text .container .media-col .inline-pic.instagram-post, .image-w-text .container .media-col .inline-pic img.instagram-post {
        box-shadow: 0px 1px 6px 2px rgba(20, 20, 20, 0.36); }

.image-w-text.alt-layout .text-col {
  order: 2; }

.image-w-text.alt-layout .media-col {
  order: 1; }

#exp-icons {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0px 20px; }
  @media (max-width: 799px) {
    #exp-icons {
      padding: 0px; } }
  @media (max-width: 560px) {
    #exp-icons {
      display: block; } }
  #exp-icons:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #979797;
    position: absolute;
    top: 42%;
    left: 0px; }
    @media (max-width: 560px) {
      #exp-icons:after {
        display: none; } }
  #exp-icons .icon {
    position: relative;
    z-index: 10;
    flex: 0 1 150px; }
    @media (max-width: 560px) {
      #exp-icons .icon {
        width: 100%;
        float: left;
        border-bottom: solid 1px #d6d6d6;
        margin-bottom: 20px; }
        #exp-icons .icon:last-of-type {
          border-bottom: none;
          margin-bottom: 0px; } }
    #exp-icons .icon .svg-wrap {
      position: relative; }
      @media (max-width: 560px) {
        #exp-icons .icon .svg-wrap {
          width: 30%;
          display: inline-block;
          float: left; } }
    #exp-icons .icon .icon-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 100; }
    #exp-icons .icon svg {
      width: 100%;
      height: auto;
      cursor: pointer; }
    #exp-icons .icon #pic1 {
      z-index: 10; }
    #exp-icons .icon #pic2 {
      z-index: 9; }
    #exp-icons .icon h3 {
      font-size: 17px;
      font-weight: 600;
      color: #6E6E6E;
      text-align: center; }
      @media (max-width: 560px) {
        #exp-icons .icon h3 {
          width: 70%;
          text-align: left;
          display: inline-block;
          float: right;
          padding: 0px 20px;
          margin-bottom: 5px; } }
    #exp-icons .icon .desc {
      width: 100%;
      text-align: center;
      opacity: 0;
      transform: translateY(-30px);
      padding: 0px 10px;
      font-size: 12px;
      color: #2f2f2f; }
      @media (max-width: 560px) {
        #exp-icons .icon .desc {
          width: 70%;
          text-align: left;
          float: right;
          padding: 0px 20px 20px 20px; } }
    #exp-icons .icon.active h3 {
      color: #000; }

.headline-xxl {
  font-family: 'Dosis', sans-serif;
  font-weight: normal;
  font-size: 26px;
  margin: 0 0 10px 0;
  color: #292929;
  text-transform: uppercase;
  width: 100%; }
  @media (min-width: 450px) {
    .headline-xxl {
      font-size: 32px; } }
  @media (min-width: 768px) {
    .headline-xxl {
      font-size: 42px; } }

.headline-l {
  font-weight: 300;
  font-size: 18px;
  color: #414141;
  margin-top: 0px;
  margin-bottom: 0.8em;
  width: 100%; }
  .headline-l.in-text {
    margin-top: 1.5em; }
  @media (min-width: 768px) {
    .headline-l {
      font-size: 20px; } }

.headline-m {
  font-size: 15px;
  font-weight: 700;
  color: #292929; }

#contact-us {
  padding-bottom: 80px; }
  #contact-us #logo-mark {
    width: 45%;
    margin-top: 45px;
    float: left;
    text-align: center; }
    @media (max-width: 768px) {
      #contact-us #logo-mark {
        display: none; } }
    #contact-us #logo-mark img {
      width: 70%; }
  #contact-us #contact-holder {
    margin-top: 45px;
    width: 50%;
    float: right;
    overflow: hidden;
    position: relative; }
    @media (max-width: 768px) {
      #contact-us #contact-holder {
        width: 100%; } }
    #contact-us #contact-holder #thanks-msg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      padding-top: 100px;
      opacity: 0;
      transform: translateY(15px);
      transition: all 0.15s ease-in;
      transition-delay: 0.40s; }
      #contact-us #contact-holder #thanks-msg h1 {
        width: 100%;
        display: inline-block;
        text-align: center; }
      #contact-us #contact-holder #thanks-msg h2 {
        width: 100%;
        display: inline-block;
        text-align: center;
        color: #000; }
    #contact-us #contact-holder #mail-form {
      position: relative;
      transition: opacity 0.25s ease-in; }
    #contact-us #contact-holder.sent #mail-form {
      opacity: 0.15; }
    #contact-us #contact-holder.sent #thanks-msg {
      opacity: 1;
      transform: translateY(-15px); }
    #contact-us #contact-holder.sent .form-field button.contact-submit {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none; }
    #contact-us #contact-holder .form-field {
      width: 100%;
      float: left; }
      #contact-us #contact-holder .form-field label {
        font-weight: 600;
        padding-bottom: 2px;
        display: inline-block; }
      #contact-us #contact-holder .form-field input, #contact-us #contact-holder .form-field textarea {
        width: 100%;
        padding: 12px 9px;
        font-size: 14px;
        background: #F1F1F1;
        border: solid 1px transparent;
        box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.5);
        border-radius: 1px; }
        #contact-us #contact-holder .form-field input[disabled='disabled'], #contact-us #contact-holder .form-field textarea[disabled='disabled'] {
          opacity: 0.5; }
        #contact-us #contact-holder .form-field input.invalid, #contact-us #contact-holder .form-field textarea.invalid {
          border: solid 1px red; }
      #contact-us #contact-holder .form-field textarea {
        font-size: 12px;
        min-height: 180px; }
      #contact-us #contact-holder .form-field button.contact-submit {
        float: right;
        color: #D29E27;
        font-size: 15px;
        padding: 15px 60px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        background: #fff;
        border: solid 1px #D29E27;
        cursor: pointer;
        transition: all 0.5s ease-out; }
        #contact-us #contact-holder .form-field button.contact-submit:hover {
          background: #D29E27;
          color: #fff; }
        @media (max-width: 560px) {
          #contact-us #contact-holder .form-field button.contact-submit {
            width: 100%; } }
      @media (min-width: 560px) {
        #contact-us #contact-holder .form-field.half {
          width: 50%; } }
      @media (min-width: 560px) {
        #contact-us #contact-holder .form-field.half:nth-of-type(even) {
          padding-left: 10px; } }
      @media (min-width: 560px) {
        #contact-us #contact-holder .form-field.half:nth-of-type(odd) {
          padding-right: 10px; } }

.grecaptcha-badge {
  visibility: hidden; }

.two-up-text .container {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start; }
  @media (min-width: 650px) {
    .two-up-text .container {
      flex-flow: row wrap;
      justify-content: space-between; } }
  .two-up-text .container .text-col {
    flex: 0 1 100%;
    margin-top: 50px; }
    @media (min-width: 650px) {
      .two-up-text .container .text-col {
        flex: 0 1 45%; } }
    .two-up-text .container .text-col ul {
      margin-bottom: 30px; }
      .two-up-text .container .text-col ul li {
        margin-bottom: 16px; }
    .two-up-text .container .text-col .take-away {
      display: inline-block;
      padding-left: 40px;
      font-size: 14px; }

.phone-chrome {
  width: 100%;
  position: relative;
  width: 60%;
  left: 15%;
  margin-top: 20px; }
  @media (max-width: 799px) {
    .phone-chrome {
      width: 90%;
      left: 5%; } }

.phone-chrome-mask {
  width: 100%; }

.phone-chrome-pic {
  position: absolute;
  z-index: -1;
  height: calc(100% - 5px);
  width: calc(100% - 10%);
  left: 5%; }
  @media (max-width: 799px) {
    .phone-chrome-pic {
      height: calc(100% - 4px); } }

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans', sans-serif;
  color: #414141;
  font-size: 16px;
  background: #F9F9F9; }
  @media (max-width: 799px) {
    body {
      font-size: 15px; } }
  body.locked {
    overflow-y: hidden; }
  body.modal-takeover #top-nav {
    z-index: 99; }
  body.m-nav-open #top-nav {
    z-index: 9999; }
    body.m-nav-open #top-nav #main-nav {
      display: flex; }
  body.m-nav-open #cover {
    background: #D29E27; }
    body.m-nav-open #cover.visable {
      opacity: 0.8; }

.inline-pic img {
  max-width: 100%; }

.underline {
  text-decoration: underline; }

.container {
  max-width: 1140px;
  margin: 0px auto;
  padding: 0px 20px; }
  @media (min-width: 560px) {
    .container {
      padding: 0px 60px; } }

#top-nav {
  width: 100%;
  height: 66px;
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  background: #fff; }
  #top-nav .container {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1175px) {
      #top-nav .container {
        padding-right: 0px; } }
  #top-nav #logo-link {
    display: inline-block; }
    #top-nav #logo-link img {
      width: 260px; }
      @media (max-width: 900px) {
        #top-nav #logo-link img {
          width: 220px; } }
  #top-nav #hamburger {
    display: none;
    width: 30px;
    height: 23px;
    position: relative;
    margin-right: 20px;
    cursor: pointer; }
    #top-nav #hamburger span {
      width: 100%;
      height: 1px;
      background: #292929;
      position: absolute;
      top: 0px;
      left: 0px; }
      #top-nav #hamburger span:nth-of-type(1) {
        top: 0px; }
      #top-nav #hamburger span:nth-of-type(2) {
        top: 50%; }
      #top-nav #hamburger span:nth-of-type(3) {
        top: auto;
        bottom: 0px; }
      #top-nav #hamburger span:nth-of-type(4) {
        top: 50%;
        opacity: 0; }
    @media (max-width: 800px) {
      #top-nav #hamburger {
        display: inline-block; } }
  #top-nav #main-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    font-size: 13px; }
    @media (max-width: 800px) {
      #top-nav #main-nav {
        display: none;
        flex-flow: column nowrap;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -55%, 0);
        width: 100%;
        padding: 0px; } }
    #top-nav #main-nav li {
      display: inline-block; }
      @media (min-width: 801px) {
        #top-nav #main-nav li {
          margin-right: 18px; } }
      #top-nav #main-nav li:last-child {
        margin-right: 0px; }
      #top-nav #main-nav li a {
        color: #292929;
        text-decoration: none;
        position: relative; }
        @media (max-width: 800px) {
          #top-nav #main-nav li a {
            padding: 15px;
            display: inline-block;
            font-size: 20px; } }
        #top-nav #main-nav li a:after {
          content: "";
          width: 0%;
          height: 1px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -4px;
          background: #D29E27;
          transition: all 0.3s ease-out; }
        #top-nav #main-nav li a.active:after {
          width: 100%; }
        @media (min-width: 801px) {
          #top-nav #main-nav li a#nav-contact-us {
            background: #D29E27;
            color: #fff;
            padding: 24px 52px;
            text-align: center;
            display: inline-block; } }
        #top-nav #main-nav li a#nav-contact-us:after {
          display: none; }
        @media (max-width: 900px) and (min-width: 801px) {
          #top-nav #main-nav li a#nav-contact-us {
            padding: 24px 30px; } }

#main-wrapper {
  display: flex;
  flex-flow: column nowrap; }

section {
  width: 100%;
  display: inline-block;
  flex: 0 1 100%;
  padding-top: 45px; }
  section.padded-bottom {
    padding-bottom: 45px; }
  @media (min-width: 500px) {
    section {
      padding-top: 65px; }
      section.padded-bottom {
        padding-bottom: 65px; } }
  @media (min-width: 768px) {
    section {
      padding-top: 85px; }
      section.padded-bottom {
        padding-bottom: 85px; } }
  section.dark-bg {
    padding-top: 45px;
    padding-bottom: 45px;
    background: #EBEBEB; }
    @media (min-width: 500px) {
      section.dark-bg {
        padding-top: 65px;
        padding-bottom: 65px; } }
    @media (min-width: 768px) {
      section.dark-bg {
        padding-top: 85px;
        padding-bottom: 85px; } }
  section.align-right h1, section.align-right h2 {
    text-align: right; }

#hero-vid-banner {
  margin-top: 66px;
  padding-top: 0px;
  padding-bottom: 60%;
  overflow: hidden;
  position: relative;
  flex: auto; }
  @media (min-width: 900px) {
    #hero-vid-banner {
      height: 100vh;
      padding-bottom: 0; } }
  #hero-vid-banner .text {
    width: 100%;
    position: absolute;
    top: 42%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    font-family: 'Dosis', sans-serif;
    font-weight: 300;
    letter-spacing: 0.08em;
    z-index: 10;
    text-align: center;
    padding: 0 8%; }
    @media (min-width: 565px) {
      #hero-vid-banner .text {
        font-size: 32px; } }
    @media (min-width: 900px) {
      #hero-vid-banner .text {
        font-size: 52px; } }
  #hero-vid-banner .hero-text-free {
    border-bottom: solid 1px #D29E27; }
  #hero-vid-banner .bg-image {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: url("../images/hero.jpg") center center no-repeat;
    background-size: cover; }
    @media (min-width: 900px) {
      #hero-vid-banner .bg-image {
        background: url("../images/hero@2x.jpg") center center no-repeat;
        background-size: cover; } }
    #hero-vid-banner .bg-image:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: rgba(0, 0, 0, 0.65); }

#why .container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  #why .container .text-col {
    flex: 0 1 59%; }
    @media (max-width: 560px) {
      #why .container .text-col {
        flex: 0 1 100%;
        order: 2;
        margin-top: 40px; } }
  #why .container .media-col {
    flex: 0 1 39%; }
    @media (max-width: 560px) {
      #why .container .media-col {
        flex: 0 1 100%;
        order: 1; } }
  #why .container #why-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between; }
    @media (max-width: 799px) {
      #why .container #why-list {
        flex-flow: column nowrap; } }
    #why .container #why-list .why-list-col {
      flex: 0 1 48%; }
      @media (max-width: 799px) {
        #why .container #why-list .why-list-col {
          flex: 0 1 100%; } }
      #why .container #why-list .why-list-col:first-of-type .why-list-item:nth-of-type(1) h3:before {
        content: "1"; }
      #why .container #why-list .why-list-col:first-of-type .why-list-item:nth-of-type(2) h3:before {
        content: "2"; }
      #why .container #why-list .why-list-col:first-of-type .why-list-item:nth-of-type(3) h3:before {
        content: "3"; }
      #why .container #why-list .why-list-col:first-of-type .why-list-item:nth-of-type(4) h3:before {
        content: "4"; }
      #why .container #why-list .why-list-col:last-of-type .why-list-item:nth-of-type(1) h3:before {
        content: "5"; }
      #why .container #why-list .why-list-col:last-of-type .why-list-item:nth-of-type(2) h3:before {
        content: "6"; }
      #why .container #why-list .why-list-col:last-of-type .why-list-item:nth-of-type(3) h3:before {
        content: "7"; }
      #why .container #why-list .why-list-col .why-list-item {
        width: 100%;
        display: block;
        margin-bottom: 20px; }
        #why .container #why-list .why-list-col .why-list-item h3 {
          position: relative;
          margin-top: 0px;
          margin-bottom: 11px;
          color: #414141;
          font-size: 16px;
          font-weight: bold;
          padding-left: 26px; }
          #why .container #why-list .why-list-col .why-list-item h3:before {
            position: absolute;
            left: 0px;
            top: 3px;
            width: 18px;
            height: 18px;
            border: solid 1px #D29E27;
            border-radius: 50%;
            text-align: center;
            font-size: 11px;
            color: #D29E27; }
        #why .container #why-list .why-list-col .why-list-item p:first-of-type {
          margin-top: 0px; }

#why #iphone-holder {
  width: 80%;
  float: left;
  width: 70%;
  max-width: 260px;
  margin-left: 5%;
  position: relative; }
  @media (max-width: 799px) {
    #why #iphone-holder {
      width: 90%; } }
  #why #iphone-holder img#iphone {
    width: 100%;
    float: left;
    z-index: 10;
    position: relative;
    backface-visibility: hidden; }
  #why #iphone-holder #strip-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    overflow: hidden;
    padding: 25% 8% 0 7%; }
    #why #iphone-holder #strip-holder img#strip {
      width: 101%;
      position: relative;
      top: 0px;
      left: 0px;
      backface-visibility: hidden; }

#our-partners #slides-nav {
  width: 28%;
  float: left;
  background: #D8D8D8; }
  @media (max-width: 799px) {
    #our-partners #slides-nav {
      width: 25%; } }
  @media (max-width: 560px) {
    #our-partners #slides-nav {
      display: none; } }
  #our-partners #slides-nav a {
    width: 100%;
    float: left;
    padding: 15px 27px 0px 27px;
    cursor: pointer;
    position: relative;
    transition: all 0.5s ease-out; }
    @media (max-width: 799px) {
      #our-partners #slides-nav a {
        padding: 15px 15px 0px 15px; } }
    #our-partners #slides-nav a:after {
      content: "";
      width: 30px;
      height: 54px;
      top: 10px;
      right: 15px;
      position: absolute;
      background: url("../images/slide_arrow.svg") center center no-repeat;
      opacity: 0;
      transition: all 0.2s ease-out; }
      @media (max-width: 799px) {
        #our-partners #slides-nav a:after {
          width: 20px;
          height: 30px;
          top: 20px; } }
    #our-partners #slides-nav a:not(.active):hover {
      background: #dedbdb; }
    #our-partners #slides-nav a .title {
      width: 100%;
      color: #6E6E6E;
      font-size: 20px;
      float: left;
      font-weight: 600; }
      @media (max-width: 799px) {
        #our-partners #slides-nav a .title {
          font-size: 14px; } }
    #our-partners #slides-nav a .location {
      width: 100%;
      color: #414141;
      font-size: 12px;
      border-bottom: solid 1px #C6C6C6;
      float: left;
      padding-bottom: 15px; }
    #our-partners #slides-nav a.active {
      background: #fff; }
      #our-partners #slides-nav a.active:after {
        opacity: 1;
        right: 10px; }
      #our-partners #slides-nav a.active .location {
        border-bottom: solid 1px transparent; }

#our-partners #partner-slides {
  float: right;
  width: 70%;
  background: #c5c5c5;
  display: inline-block;
  margin-bottom: 40px; }
  @media (max-width: 799px) {
    #our-partners #partner-slides {
      width: 73%; } }
  @media (max-width: 560px) {
    #our-partners #partner-slides {
      width: 100%; } }
  #our-partners #partner-slides .slide {
    padding: 0px; }
  #our-partners #partner-slides .slick-prev, #our-partners #partner-slides .slick-next {
    width: 42px;
    height: 72px;
    z-index: 999;
    background: rgba(92, 92, 92, 0.6) url("../images/slide_arrow_white.svg") center center no-repeat; }
    #our-partners #partner-slides .slick-prev:before, #our-partners #partner-slides .slick-next:before {
      content: ""; }
    #our-partners #partner-slides .slick-prev:hover, #our-partners #partner-slides .slick-next:hover {
      background: rgba(92, 92, 92, 0.7) url("../images/slide_arrow_white.svg") center center no-repeat; }
  #our-partners #partner-slides .slick-prev {
    left: 0px;
    transform-origin: center center;
    transform: rotate(180deg);
    margin-top: -36px; }
  #our-partners #partner-slides .slick-next {
    right: 0px; }

#our-partners #logo-gallery {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }
  @media (max-width: 560px) {
    #our-partners #logo-gallery {
      justify-content: space-between; } }
  @media (min-width: 561px) {
    #our-partners #logo-gallery {
      width: 90%;
      margin: 0 5%;
      margin-top: 40px; } }
  #our-partners #logo-gallery .logo {
    flex: 0 1 20%;
    padding: 30px;
    text-align: center; }
    @media (max-width: 799px) {
      #our-partners #logo-gallery .logo {
        flex: 0 1 25%;
        padding: 20px;
        font-size: 12px; } }
    @media (max-width: 560px) {
      #our-partners #logo-gallery .logo {
        flex: 0 1 45%; } }
    #our-partners #logo-gallery .logo a {
      transition: opacity 80ms ease-in; }
      #our-partners #logo-gallery .logo a:hover {
        opacity: 0.8; }
    #our-partners #logo-gallery .logo .bar-name {
      color: #6e6e6e;
      font-size: 16px;
      font-weight: 700;
      width: 100%;
      display: inline-block;
      margin-top: 10px; }
      @media (max-width: 799px) {
        #our-partners #logo-gallery .logo .bar-name {
          font-size: 12px; } }
    #our-partners #logo-gallery .logo img, #our-partners #logo-gallery .logo svg {
      width: 100%;
      display: inline-block;
      border: solid 1px #7e7d7c;
      border-radius: 50%;
      padding: 10px;
      background: #7e7d7c;
      fill: #f9f9f9; }

#about-us .text-col {
  width: 75%;
  margin-bottom: 45px; }
  @media (max-width: 560px) {
    #about-us .text-col {
      width: 100%; } }

#about-us #bio-grid {
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  @media (max-width: 799px) {
    #about-us #bio-grid {
      justify-content: space-around; } }
  #about-us #bio-grid .bio {
    flex: 0 1 15%;
    text-align: center;
    font-size: 13px; }
    @media (max-width: 799px) {
      #about-us #bio-grid .bio {
        flex: 0 1 18%; } }
    @media (max-width: 560px) {
      #about-us #bio-grid .bio {
        flex: 0 1 40%;
        padding: 20px 0px; } }
    #about-us #bio-grid .bio h3 {
      color: #414141;
      font-size: 15px;
      font-weight: bold;
      margin-top: 12px;
      margin-bottom: 5px; }
      @media (max-width: 799px) {
        #about-us #bio-grid .bio h3 {
          font-size: 12px; } }
    #about-us #bio-grid .bio .role {
      font-size: 12px; }
      @media (max-width: 799px) {
        #about-us #bio-grid .bio .role {
          font-size: 12px; } }
    #about-us #bio-grid .bio img {
      width: 100%;
      display: inline-block;
      clip-path: circle(50% at center); }

footer {
  background: #909090;
  color: #d0d0d0;
  text-align: center;
  padding: 32px; }
  @media (max-width: 560px) {
    footer {
      font-size: 12px; } }
  footer a {
    color: #fff; }
  footer #social-links {
    margin-bottom: 15px; }
    footer #social-links a {
      margin: 0px 15px;
      display: inline-block;
      position: relative;
      cursor: pointer; }
      footer #social-links a:hover {
        opacity: 0.7; }
      footer #social-links a img {
        width: 30px; }
        @media (max-width: 560px) {
          footer #social-links a img {
            width: 20px;
            margin-top: 5px; } }
      footer #social-links a:first-of-type:after {
        content: "";
        width: 1px;
        height: 28px;
        background: #c9c9c9;
        position: absolute;
        top: 2px;
        right: -17px; }

#cover {
  z-index: 9998;
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s linear; }
  #cover.visible {
    pointer-events: auto;
    opacity: 1; }

#vid-holder {
  padding: 120px 20px 20px 20px;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: none; }
  #vid-holder #vid-placeholder {
    width: 100%;
    max-width: 940px;
    display: inline-block;
    border: solid 1px #4a4a4a;
    overflow: hidden; }
    #vid-holder #vid-placeholder img#vid-poster {
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px; }
    #vid-holder #vid-placeholder #vid-wrap {
      padding-bottom: 56.7%; }
      #vid-holder #vid-placeholder #vid-wrap #herovid {
        width: 100%;
        display: inherit;
        position: absolute;
        top: 0px;
        left: 0px; }
  #vid-holder #vid-close-but {
    position: absolute;
    top: 40px;
    right: 20px;
    cursor: pointer; }
    #vid-holder #vid-close-but img {
      width: 40px; }
    #vid-holder #vid-close-but:hover img {
      opacity: 0.8; }

.download-link {
  display: flex;
  align-items: center;
  margin-top: 34px;
  text-decoration: none;
  color: inherit; }
  .download-link:hover {
    opacity: 0.6; }
  .download-link img {
    margin-right: 7px; }

.pricing-text {
  width: 65%; }
  @media (max-width: 799px) {
    .pricing-text {
      width: 100%; } }

.pricing-text-list li {
  margin-bottom: 30px; }

.free-tag {
  font-size: 13px;
  font-weight: bold;
  border-bottom: solid 1px #d29e27;
  margin-left: 5px; }
